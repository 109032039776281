<template>
  <div>
    <div id="goback" style="margin-bottom: 10px; font-size: 14px">
      <i
        class="el-icon-back"
        @click="$router.go(-1)"
        style="cursor: pointer; font-size: 14px"
        >返回</i
      >&nbsp;&nbsp;
      <span style="font-size: 14px; color: rgb(192, 196, 204)">|</span>
      &nbsp;&nbsp;<span style="font-size: 16px; color: rgb(192, 196, 204)"
        >编号：</span
      >{{ pcode }}
      <span style="font-size: 14px; color: rgb(192, 196, 204)">项目：</span
      >{{ pname }}
    </div>

    <div id="content">
      <div class="part1">
        
        <table height="45" style="border-bottom: 2px solid #efefef;font-size:16px">
          <tr>
          <td width="400">文件名</td>        
          <td width="100">大小</td>        
          <td width="230">修改时间</td>        
          <td width="60">操作</td>        
        </tr>
        </table>
        <div v-if="lgs.length<=0" style="color:#999;padding:30px 200px">暂无数据</div>
         <div  v-for="(lg,index) in lgs" :key="index">
          <table height="45" style="border-bottom: 1px solid #efefef"><tr>
          <td width="400">
            {{lg[0]}}
          </td>        
          <td width="100">
            {{lg[1]}}
          </td>        
          <td width="230">
            {{lg[2]}}
          </td>        
          <td width="60">
            <el-button type="text" @click="pdowndata(lg[0])">下载</el-button>
          </td>        
          
        </tr>
        </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RemoteLog",
  props: ["pid", "pname", "pcode"],
  data() {
    return {  
      token: "Bearer " + localStorage.getItem("token"),
      lgs:[],
    };
  },
  methods: {
   
    getLog() {      
      var api = this.COMMON.httpUrl + "remoteLogList";
      var data = {pid:this.pid}               
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message)
        } else {
          this.lgs=rs.data
        }
      });
    },
     // 文件下载
     pdowndata(fname) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify({ pid: this.pid, fname:fname }),
      };

      let src = this.COMMON.httpUrl + "remoteLogDown";
      fetch(src, requestOptions)
        .then((response) => {
          if (!response.ok) {
            // 如果响应不是成功的，尝试读取JSON格式的错误信息
            return response.json().then((errorData) => {
              // 假设错误数据是一个包含code和message的对象
              if (errorData.code && errorData.message) {
                // 抛出错误以便后续的.catch可以捕获
                throw new Error(errorData.message);
              } else {
                // 如果格式不符合预期，可以抛出一个通用的错误
                throw new Error("下载异常");
              }
            });
          }
          return response.blob();
        })
        // .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          // 文件名称可以在这里更改
          a.download = fname;
          a.href = url;
          a.click();
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
   
  },

  created() {
     this.getLog()
  },
};
</script>
<style scoped>
#content {
  background-color: white;
  padding-top: 20px;
  padding-left: 20px;
}

.part1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.con{
  font-size:14px;
  width:1000px;
}
.con span.t {
  font-size:16px;
  font-weight: 800;
}
.con span{
  margin-right:20px;
}
.upload {
  display: inline-block;
}
</style>