<template>
  <div>
    <div id="content">
      <h1>晨星平台保密声明</h1>
      <div class="section">  特此声明：
      </div>
    <div class="section">
        <h2>1. 保密范围</h2>
        <ul>
            <li>本保密声明适用于“上海甲业信息科技有限公司”（以下简称"本公司"）开发并运营的晨星平台（以下简称"本平台"）的所有内容，包括但不限于：</li>
            <ul style="margin-left: 20px;">
                <li>平台所有功能模块及其实现方式</li>
                <li>用户界面设计及交互流程</li>
                <li>平台上存储、展示或传输的所有数据及文档</li>
                <li>系统架构、业务流程及相关技术文档</li>
                <li>平台相关的所有知识产权</li>
            </ul>
        </ul>
    </div>
    <div class="section">
        <h2>2. 知识产权声明</h2>
        <ul>
            <li>本平台及其所有组成部分均为上海甲业信息科技有限公司的专有财产，受中华人民共和国《中华人民共和国公司法》、《中华人民共和国商标法》、《中华人民共和国专利法》、《著作权法》、《反不正当竞争法》、《中华人民共和国刑法》法律法规的保护。</li>
            <li>本公司对平台的所有内容享有完整的知识产权。</li>
        </ul>
    </div>
    <div class="section">
        <h2>3. 保密义务</h2>
        <div><br>
            
            <ul>
                <li>所有访问和使用本平台的内部人员及外部人员都必须严格遵守以下保密义务：</li>
                <ul style="margin-left: 20px;">
                    <li>不得以任何形式向外部机构或个人泄露平台相关信息</li>
                    <li>不得将平台内容用于本公司授权范围以外的目的</li>
                    <li>不得复制、传播或分享平台的任何组成部分</li>
                    <li>必须采取必要措施保护平台信息安全</li>
                </ul>
            </ul>
            
            <ul>
                <li>本保密义务在内部人员及外部人员离职后继续有效。</li>
            </ul>
        </div>
    </div>
    <div class="section">
        <h2>4. 法律责任</h2>
        <ul>
            <li>违反本保密声明的行为将被视为严重违反公司规定，本公司将保留采取以下措施的权利：</li>
            <ul style="margin-left: 20px;">
                <li>立即终止违规人员的平台使用权限</li>
                <li>要求违规人员承担由此造成的所有经济损失</li>
                <li>依据《劳动合同法》《中华人民共和国公司法》、《中华人民共和国商标法》、《中华人民共和国专利法》、《著作权法》、《反不正当竞争法》、《中华人民共和国刑法》相关法律法规追究违规人员的法律责任</li>
                <li>针对造成重大损失的行为提起民事诉讼或刑事控告</li>
            </ul>
        </ul>
    </div>
    <div class="section">
        <h2>5. 承诺确认</h2>
        <ul>
            <li>内部人员及外部人员登录并使用本平台即视为已完全理解并同意本保密声明的全部内容，并承诺严格遵守上述各项规定。</li>
        </ul>
    </div>
    <div class="section">
        <h2>6. 其他条款</h2>
        <ul>
            <li>本声明的解释权归上海甲业信息科技有限公司所有。</li>
            <li>本公司保留根据实际情况修改本声明的权利，修改后的声明自发布之日起生效。</li>
        </ul>
    </div>
    <div class="footer">
        <p>特此声明。</p>
        <p>上海甲业信息科技有限公司</p>
    </div>




    </div>
  </div>
</template>
<script>
export default {

  name: 'PageXieYi',
 
  data() {
    return {
 
  
    };
  },

}
</script>
<style scoped>
  #content {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            margin: 0;
            padding: 20px;
            background-color: #fff;
            width:1200px;
        }
        h1 {
            color: #333;
            text-align: center;
            font-size:30px;
            margin-bottom: 20px;
        }
        .section {
            margin-bottom: 20px;
            background-color: #fff;
            padding: 20px;            
        }
        .section h2 {
            color: #555;
            margin-top: 0;
        }
        .section ul {
            list-style-type: none;
            padding: 0;
        }
        .section ul li {            
            padding-left: 20px;
            margin-bottom: 10px;
        }
        .footer {
            text-align: center;
            margin-top: 20px;
        }
</style>