<template>
  <div>
    <div id="goback" style="margin-bottom: 10px; font-size: 14px">
      <i
        class="el-icon-back"
        @click="$router.go(-1)"
        style="cursor: pointer; font-size: 14px"
        >返回</i
      >&nbsp;&nbsp;
      <span style="font-size: 14px; color: rgb(192, 196, 204)">|</span>
      &nbsp;&nbsp;<span style="font-size: 16px; color: rgb(192, 196, 204)"
        >编号：</span
      >{{ pcode }}
      <span style="font-size: 14px; color: rgb(192, 196, 204)">项目：</span
      >{{ pname }}
    </div>

    <div id="content">

      <div class="part1">
        <div class="con">          
          <span class="t">项目状态:</span>&nbsp;&nbsp;<span style="color:#F56C6C"> {{remote.state}}</span>          
          <span>随机数目录名：{{remote.dirname}}</span>

          <el-divider></el-divider>
        </div>        
        
        <div class="con">          
          <span class="t">Links:</span>

          &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;<el-button  type="info" size="small" plain   @click="pdowndata('links.xlsx')">下载</el-button>
          &nbsp;&nbsp;&nbsp;
          <el-upload
          class="upload"
          name="links"
          :action="upurl"
          :headers="{ Authorization: token }"
          :data="{ pid: pid }"
          auto-upload
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-success="onSuccess"
          ><el-button type="info" plain   size="small"
            >上传</el-button
          ></el-upload> &nbsp;&nbsp;&nbsp;
          <span class="upinfo" v-show="remote.upuser!=null && remote.upuser!=''">由 {{remote.upuser}}</span><span class="upinfo" v-show="remote.upuser!=null && remote.upuser!=''"> 上传于 {{remote.uptime}}</span>
          <el-divider></el-divider>
        </div>   

        <div class="con">          
          <span class="t">项目数据:</span>
          &nbsp;&nbsp;
          <el-button  type="info" plain  @click="diversity()" size="small">diversity</el-button>  
          <el-button  type="info" plain  @click="sendDatamap()" size="small">发送datamap</el-button>          
          <el-button  type="info" plain  @click="sendLogic" size="small">发送logic</el-button>            
          <el-divider></el-divider>       
        </div>   
 

        <div class="con">          
          <span class="t">启动程序:</span>&nbsp;&nbsp;&nbsp;<el-input v-model="paoparam.total" placeholder="总数:"  size="small" style="width:200px"></el-input>
          <div style="padding-left:93px"><br>
          <el-input v-model="paoparam.threads" placeholder="并发数:"  size="small" style="width:200px"></el-input><br><br>
          <el-input v-model="paoparam.step" placeholder="震荡步长:" size="small" style="width:200px"></el-input><br><br>
          <el-button  type="primary"  @click="remoteRun"  size="small">开始运行</el-button>
          <el-button  type="info" plain @click="updateJindu"  size="small">刷新进度</el-button>
          <span style="margin-left:50px">当前进度：<span class="progress" :class="{ 'animate':remote.isAnimating }">{{remote.jindu}}</span></span>
          <span style="margin-left:20px">
          <router-link  style="color:#409EFF"  :to="{
                  name: 'remotelog',
                  params: {
                    pid: pid,
                    pcode:pcode,
                    pname:pname,
                  },
                }">运行日志</router-link></span>      
        </div>
          
          <el-divider></el-divider>
        </div>   
      </div>      

    <div class="part1">      
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RemoteSet",
  props: ["pid", "pname", "pcode"],
  data() {
    return {
      upurl: this.COMMON.httpUrl + "remoteSendLinks",
      token: "Bearer " + localStorage.getItem("token"),
      paoparam:{
        total:"",
        threads:"",
        delay:"0",
        step:"0"
      },
      remote:{
        jindu:"0/0",
        isAnimating: false, 
        dirname:"",
        state:"",
        upuser:"",
        uptime:""
      },
      
    };
  },
  methods: {
    remoteRun() {
      if (this.paoparam.total.trim().length==0 ||this.paoparam.threads.trim().length==0){
        alert("总数和并发数不能为空！")
        return
      }      
      var api = this.COMMON.httpUrl + "remoteRun";
      var data = this.paoparam
         data.pid=this.pid        
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message)
        } else {
          this.$message.success(rs.message)
          this.jindu=rs.data
        }
      });
    },
    updateJindu() {
       this.remote.isAnimating = true; // 触发动画
      // 设置定时器，在动画结束后重置动画状态
      setTimeout(() => {
        this.remote.isAnimating = false;
      }, 1000); // 动画持续500毫秒
      // 更新进度值
      this.getRemoteInfo() 
    },
    getRemoteInfo() {      
      var api = this.COMMON.httpUrl + "remoteState";
      var data = {pid:this.pid}               
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message)
        } else {
          this.remote=rs.data
        }
      });
    },
    diversity() {      
 
      var api = this.COMMON.httpUrl + "remoteDiversity";
      var data = {pid:this.pid}               
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message)
        } else {
          this.$message.success(rs.message);
        }
      });
    },
    sendDatamap() {      
      var api = this.COMMON.httpUrl + "remoteSendDatamap";
      var data = {pid:this.pid}               
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message)
        } else {
          this.$message.success(rs.message);
        }
      });
    },
    sendLogic() {      
      var api = this.COMMON.httpUrl + "remoteSendLogic";
      var data = {pid:this.pid}               
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message)
        } else {
          this.$message.success(rs.message);
        }
      });
    },
    beforeAvatarUpload(file) {
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        return false;
      }
    },
    onSuccess(rs) {
      if (rs.code) {
        this.$message.error(rs.message);
      } else {
        this.$message.success(rs.message);
      }
    },
  
 
  
    // 文件下载
    pdowndata(fname) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify({ pid: this.pid, fname:fname }),
      };

      let src = this.COMMON.httpUrl + "remoteLogDown";
      fetch(src, requestOptions)
        .then((response) => {
          if (!response.ok) {
            // 如果响应不是成功的，尝试读取JSON格式的错误信息
            return response.json().then((errorData) => {
              // 假设错误数据是一个包含code和message的对象
              if (errorData.code && errorData.message) {
                // 抛出错误以便后续的.catch可以捕获
                throw new Error(errorData.message);
              } else {
                // 如果格式不符合预期，可以抛出一个通用的错误
                throw new Error("下载异常");
              }
            });
          }
          return response.blob();
        })
        // .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          // 文件名称可以在这里更改
          a.download = fname;
          a.href = url;
          a.click();
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },

  created() {
     this.getRemoteInfo()
  },
};
</script>
<style scoped>
#content {
  background-color: white;
  padding-top: 20px;
  padding-left: 20px;
}

.part1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.con{
  font-size:14px;
  width:1000px;
}
.con span.t {
  font-size:14px;
  font-weight: 800;
}
.con span{
  margin-right:20px;
}
.upload {
  display: inline-block;
}
.upinfo{
  color:#afafaf;
  margin-left:10px;
  font-size:12px;
}


.progress {  
  opacity: 1; /* 初始不透明 */
  transition: top 0.5s, opacity 0.5s; /* 落入效果 */
}

/* 定义动画 */
@keyframes dropIn {
  0% {
    top: -50px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

/* 动画应用 */
.animate {
  animation: dropIn 1s forwards; /* 动画完成后保持最后一帧的状态 */
}
</style>