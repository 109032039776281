<template>
  <div>
    <j-hover-btn
      bgColor="#f56c6c"
      text="Edit"
      :btn-style="btnStyle"
      @hoverBtnClick="editShow = true"
    >
    </j-hover-btn>

    <el-dialog title="Edit" :visible.sync="editShow" top="8vh" width="65%">
      <div class="edit">
        <el-row>
          <el-col :span="10">
            <div class="anl">
              <el-input
                v-model="keyword"
                placeholder="搜索"
                clearable
                size="small"
                style="width: 184px; margin-right: 60px"
              ></el-input>
              <el-button size="small" :loading="eload" plain @click="sync"
                >同类题选项处理</el-button
              ><el-button
                size="small"
                type="primary"
                @click="add"
                :loading="eload"
                >添加</el-button
              >
            </div>
            <div class="ezuo">
              <el-cascader-panel
                v-model="sidSe"
                :options="optionsFil"
                :props="{ multiple: true, checkStrictly: false }"
              ></el-cascader-panel>
            </div>
            <div class="ezuo">
              <el-select
                v-model="hdSe"
                multiple
                clearable
                collapse-tags
                placeholder="请选择表头"
              >
                <el-option
                  v-for="(item, index) in hd"
                  :key="index"
                  :label="item.alabel"
                  :value="item.cond"
                >
                </el-option>
              </el-select>
            </div>
            <div class="ezuo">
              <el-input
              clearable
                v-model="start"
                placeholder="start"
                class="pz"
              ></el-input
              ><el-input v-model="end" clearable placeholder="end" class="pz"></el-input>
            </div>
            <div class="ezuo">
              <el-radio v-model="radio" label="1">scene</el-radio>
              <el-radio v-model="radio" label="2">scene super</el-radio>
            </div>
            <div class="ezuo">
              <el-checkbox-group v-model="duo">
                <p class="dx">
                  <el-checkbox label="A1"
                    >key变量选中时，values变量必选中</el-checkbox
                  >
                </p>
                <p class="dx">
                  <el-checkbox label="A2"
                    >key变量选中时，values变量不能选中</el-checkbox
                  >
                </p>
                <p class="dx">
                  <el-checkbox label="A3"
                    >key变量没选中时，values变量必选中</el-checkbox
                  >
                </p>
                <p class="dx">
                  <el-checkbox label="A4"
                    >key变量没选中时，values变量不能选中</el-checkbox
                  >
                </p>
              </el-checkbox-group>
            </div>
          </el-col>
          <el-col :span="2">&nbsp;</el-col>
          <el-col :span="11">
            <template v-if="jilu">
              <div class="anl">
                <el-button
                  type="text"
                  icon="el-icon-d-arrow-left"
                  @click="jilu = false"
                  >返回</el-button
                >
              </div>
              <div class="cfs">
                <div v-for="(jl, index) in jls" :key="index">
                  <p><el-divider content-position="left">{{ jl.time }}}</el-divider></p>
                  <p v-for="(cs, index) in jl.js" :key="index" class="jls">
                    <template v-if="cs.mode == 'pv'">
                      <span>{{ cs.mode }}</span> | 表侧: {{ cs.exp }} start:
                      {{ cs.start }} end: {{ cs.end }}
                    </template>
                    <template v-if="cs.mode == 'scene'">
                      <span v-if="cs.radio == 1">scene</span>

                      <span v-else>scene super</span> | 表头:
                      {{ cs.cond }} 表侧: {{ cs.exp }}
                    </template>
                    <template v-if="cs.mode == 'logic'">
                      <span>{{ cs.mode }}</span> | 表头: {{ cs.cond }} 表侧:
                      {{ cs.exp }} type:{{ cs.duo }}                      
                    </template>
                  </p>
                </div>
              
              </div>
            </template>
            <template v-else>
              <div class="anl">
                <el-button
                  size="small"
                  :loading="eload"
                  plain
                  @click="configs = []"
                  >清除</el-button
                ><el-button
                  size="small"
                  type="success"
                  :loading="eload"
                  @click="submit"
                  >执行</el-button
                >
                <el-button type="text" style="float: right" @click="jilu = true"
                  >执行记录</el-button
                >
              </div>
              <div class="cfs">
                <p v-for="(cs, index) in configs" :key="index">
                  <template v-if="cs.mode == 'pv'">
                    <span>{{ cs.mode }}</span> | 表侧: {{ cs.exp }} start:
                    {{ cs.start }} end: {{ cs.end }}
                    <i
                      class="el-icon-remove-outline"
                      style="font-size: 16px; color: #f87878"
                      @click="del(index)"
                    ></i>
                  </template>
                  <template v-if="cs.mode == 'scene'">
                    <span v-if="cs.radio == 1">scene</span>

                    <span v-else>scene super</span> | 表头: {{ cs.cond }} 表侧:
                    {{ cs.exp }}
                    <i
                      class="el-icon-remove-outline"
                      style="font-size: 16px; color: #f87878"
                      @click="del(index)"
                    ></i>
                  </template>
                  <template v-if="cs.mode == 'logic'">
                    <span>{{ cs.mode }}</span> | 表头: {{ cs.cond }} 表侧:
                    {{ cs.exp }} type:{{ cs.duo }}
                    <i
                      class="el-icon-remove-outline"
                      style="font-size: 16px; color: #f87878"
                      @click="del(index)"
                    ></i>
                  </template>
                </p>
              </div>
            </template>
          </el-col>
          <el-col :span="1">&nbsp;</el-col>
        </el-row>
      </div>
    </el-dialog>

    <div id="content" :style="{ height: dynamicHeight + 'px' }">
      <div class="part">
        <el-row>
          <el-col :span="10">          

            &nbsp;<el-button
              size="small"
              type="success"
              @click="make"
              :loading="making"
              >生成报告</el-button
            >
            &nbsp;<el-button
              size="small"
              type="primary"
              @click="getdata"
              :loading="making"
              >展示 / 刷新</el-button
            >

            &nbsp;&nbsp; &nbsp;&nbsp;默认：<el-switch
            @change="morenxm"
          v-model="moren"
          active-color="#13ce66"
          inactive-color="#bbbbbb"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
          </el-col>          
          <el-col :span="4">
            <el-select v-model="tongji" placeholder="统计单位" size="small">
              <el-option
                v-for="item in tjoption"
                :key="item.v"
                :label="item.k"
                :value="item.v"
              >
              </el-option> </el-select
          ></el-col>
          <el-col :span="4">
            <el-button
              size="small"
              type="primary"
              @click="copyElement"
              plain
              :loading="making"
              >复制表格</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="down"
              plain
              :loading="making"
              >下载Excel</el-button
            >
          </el-col>
          <el-col :span="3">&nbsp;</el-col>
        </el-row>
      </div>
      <div class="tab" id="source">
        <div v-for="(tb, index) in tbs" :key="index" class="tbs">
          <table class="tit">
            <tr>
              <td>
                <span class="numb">[{{ index + 1 }}]</span
                ><b>[{{ tb.tp }}] {{ tb.tkey }} {{ tb.qlable }}</b>
              </td>
            </tr>
          </table>

          <div>
            <table class="hh">
              <!--表头 -->
              <thead>
                <tr v-for="(hs, index) in tb.head" :key="index">
                  <th v-for="(h, index) in hs" :key="index">{{ h }}</th>
                </tr>
              </thead>
              <!-- 表体 -->
              <tbody>
                <tr v-for="(hs, row) in tb.trs" :key="row">
                  <td v-for="(h, col) in hs" :key="col" v-html="h">                    
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="border: 0px !important">
              <tr>
                <td colspan="20" style="border: 0px !important">&nbsp;</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "KpiShow",
  props: ["kid", "pid", "pname", "kname"],
  data() {
    return {
      moren:0,
      jilu: false,
      jls: [],
      eload: false,
      keyword: "",
      editShow: false,
      hd: [],
      hdSe: [],
      options: [],
      optionsFil: [],
      sidSe: [],
      start: "",
      end: "",
      radio: "",
      duo: [],
      tou: [],
      configs: [],
      making: false,
      tongji: 1,
      tjoption: [
        { k: "percent", v: 1 },
        { k: "count", v: 2 },
        { k: "percent&count", v: 3 },
      ],
            
      tbs: [],
      dynamicHeight: window.innerHeight - 207,
      btnStyle: {
        fontSize: "small",
        color: "#fff",
        top: "60vh",
        width: "60px",
        height: "60px",
        "line-height": "60px",
        left: "90vw",
        border: "4px solid #999",
      },
    };
  },
  watch: {
    keyword: {
      handler(val) {
        val = val.toLowerCase();
        this.optionsFil = this.options.filter((p) => {
          return p.label.toLowerCase().indexOf(val) !== -1;
        });
      },
    },
  },
  methods: {
    getdata() {
      this.making = true;
      var api = this.COMMON.httpUrl + "kpiData";
      var data = {
        weidu: "2",
        pid: this.pid,
        kid: this.kid,
        tongji: this.tongji,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        this.making = false;
        if (rs.code) {
          this.$message.error(rs.message);      
        } else {
          this.tbs = rs.data.da;
          this.tou = rs.data.tou;          
        }
      });
    },
    make() {
      this.making = true;
      var api = this.COMMON.httpUrl + "kpiMake";
      var data = {        
        pid: this.pid,
        kid: this.kid,
        tongji: this.tongji,
      };
      this.axios.post(api, data).then((response) => {
        this.making = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          // this.tbs = rs.data.da;
          // this.tou = rs.data.tou;       
        }
      });
    },
    down() {
      this.making = true;
      var api = this.COMMON.httpUrl + "kpiDown";
      var data = {
        pname: this.pname,
        kname: this.kname,
        pid: this.pid,
        kid: this.kid,
        tongji: this.tongji,
      };
      this.axios.post(api, data).then((response) => {
        this.making = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success("kpi数据下载中");
          window.location.href = rs.data;
        }
      });
    },
    copyElement() {
      let sourceElement = document.getElementById("source");
      let range = document.createRange();
      range.selectNode(sourceElement);
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeRange(range);
      this.$message.success("已复制表格到剪贴板");
    },

    sync() {
      if (this.sidSe.length == 0) {
        return;
      }
      this.eload = true;
      var api = this.COMMON.httpUrl + "kpiSync";
      var data = {
        pid: this.pid,
        kid: this.kid,
        sids: this.sidSe,
      };
      this.axios.post(api, data).then((response) => {
        this.making = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.sidSe = rs.data;
          this.eload = false;
        }
      });
    },
    isPositiveInteger(n) {
    return /^[1-9]\d*$/.test(n);
    },
    add() {
      // console.log( this.sidSe)
      //pv部分
      this.start = this.start.trim();
      this.end = this.end.trim();
      if (this.start.length > 0) {
        if (this.end < this.start) {
          this.end = "";
        }
        if (!this.isPositiveInteger(this.start) || !this.isPositiveInteger(this.end)) {
          alert("start end 必须输入正整数",this.start,this.end);
          return;
        }
        for (let item of this.sidSe) {
          this.configs.push({
            mode: "pv",
            qid: item[0],
            exp: item[1],
            start: this.start,
            end: this.end,
          });
        }
      }
      // scene部分
      if (
        this.radio.length > 0 &&
        this.hdSe.length > 0 &&
        this.sidSe.length > 0
      ) {
        for (let item of this.sidSe) {
          for (let cond of this.hdSe) {
            this.configs.push({
              mode: "scene",
              qid: item[0],
              exp: item[1],
              cond: cond,
              radio: this.radio,
            });
          }
        }
      }
      // logic部分
      if (
        this.duo.length > 0 &&
        this.hdSe.length > 0 &&
        this.sidSe.length > 0
      ) {
        for (let item of this.sidSe) {
          for (let cond of this.hdSe) {
            this.configs.push({
              mode: "logic",
              qid: item[0],
              exp: item[1],
              cond: cond,
              duo: this.duo,
            });
          }
        }
      }
      // 重置
      this.sidSe = [];
      this.hdSe = [];
      this.duo = [];
      this.radio = "";
      this.start = "";
      this.end = "";
    },
    del(index) {
      this.configs.splice(index, 1);
    },
    submit() {
      if (Object.keys(this.configs).length === 0) {
        return;
      }
      this.eload = true;
      var api = this.COMMON.httpUrl + "kpiEdit";
      var data = {
        configs: this.configs,
        pid: this.pid,
        kid: this.kid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success("处理完成！");
          this.configs = [];
          this.eload = false;
          this.jls = rs.data.jls;
        }
      });
    },
    headside() {
      this.making = true;
      var api = this.COMMON.httpUrl + "kpiHeadSide";
      var data = {
        pid: this.pid,
        kid: this.kid,
      };
      this.axios.post(api, data).then((response) => {
        this.making = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.hd = rs.data.hd;
          this.options = rs.data.bs;
          this.optionsFil = rs.data.bs;
          this.jls = rs.data.jls;
        }
      });
    },
    getReportalInfo() {
      
      var api = this.COMMON.httpUrl + "reportInfo";
      var data = {
        kid: this.kid,
      };
      this.axios.post(api, data).then((response) => {
        this.making = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.moren = rs.data.moren;          
        }
      });
    },
    morenxm(){
      var api = this.COMMON.httpUrl + "reportMoren";
      var data = {
        pid: this.pid,
        kid: this.kid,
        moren:this.moren
      };
      this.axios.post(api, data).then((response) => {
        this.making = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
          this.moren=0;
        } 
      });
    }
  },
  created() {
    localStorage.setItem("kpitag","one")
    this.getReportalInfo();
    this.headside();

  },
};
</script>
  <style scoped>
#content {
  overflow: auto;
  background-color: rgb(255, 255, 255);
}

.anl {
  margin-bottom: 10px;
}
.pops1 {
  padding-left: 20px;
}
.ezuo {
  margin-bottom: 20px;
}
.dx {
  height: 28px;
}
.pz {
  width: 70px;
  margin-right: 15px;
}
.jlu span {
  color: #80b8ed;
}
.pops2 .dx {
  color: #000;

  padding-bottom: 15px;
  border-bottom: 1px solid rgb(232, 224, 224);
}
.pops2 span {
  color: #b8b8b9;

  margin-left: 30px;
}
.pops2 .sc {
  text-align: left;
  padding: 15px 30px;
  border-bottom: 1px solid rgb(232, 224, 224);
}
.pops2 .lg {
  padding-top: 20px;
}
.pops2 .lg p {
  margin-top: 10px;
  padding-left: 30px;
}
.cfs {
  height: 540px;
  border: 2px dashed #e0e5f0;
  background-color: #fafafa;
  overflow: auto;
  padding: 10px 0px;
  padding-left: 20px;
  color: #999;
}
.el-divider__text{
  background-color: #fafafa;
  color:#bbbbbb ;
}
.jls{
  font-size:13px!important
}

/* 表格样式 */
table.tit {
  height: 40px;
  font-size: 16px !important;
  border: 0px !important;
  width: 100% !important;
}

table.tit td {
  height: 40px;
  font-size: 16px !important;
  border: 0px !important;
}
.numb {
  font-size: 15px !important;
  background-color: rgb(107, 111, 243);
  color: rgb(245, 241, 146);
  padding: 3px;
  margin-right: 15px;
}
/* .part{
  margin-bottom: 15px;
} */
.tbs {
  margin-top: 0px;
}
table {
  border: 1px solid rgb(176, 175, 175);
  border-collapse: collapse;
}
th,
td {
  border: 1px solid rgb(176, 175, 175);
  border-collapse: collapse;
  min-width: 40px;
  font-size: 13px;
  padding:2px 4px;
  margin: 0;
}
table.tit td {
  padding: 0;
  margin: 0;
}
thead tr {
  border: none !important;
  outline-color: rgb(210, 209, 209);
  outline-style: solid;
  outline-width: 1px;
}
table thead th {
  /* background-color: #c2daf0; 设置表头背景色 */
  background-color: #c2daf0;
  font-weight: 600;
  text-align: center;
}

table tbody td {
  text-align: center;
}
/* 第二列exp左对齐 */
table tbody td:nth-child(2){
 text-align: left;
}

table tr:nth-child(even) {
  background-color: #f2f2f2; /*设置偶数行背景色*/
}

table tr:nth-child(odd) {
  background-color: #fff; /*设置奇数行背景色*/
}

table.hh td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 10 !important;
  background-color: #f2f2f2;
  min-width: 100px !important;
  max-width: 200px !important;
}
table.hh td:nth-child(2) {
  position: sticky !important;
  left: 100px !important;
  z-index: 10 !important;
  background-color: #f2f2f2;
}
table.hh th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 10 !important;
  min-width: 100px !important;
}
table.hh th:nth-child(2) {
  position: sticky !important;
  left: 100px !important;
  z-index: 10 !important;
}
table.hh thead {
  position: sticky !important;
  top: 0;
  z-index: 20 !important;
  background-color: #c2daf0;
}

/* table.hh tr:last-child td{
  background-color: #c2daf0;
} */
</style>
<style>
.pnow{
  color:white;
  background-color: #0099ff;
  padding:0 2px;
}
</style>
   