<template>
  <div>
    <div id="content">
      <div class="anl">
        <el-button type="primary" @click="addform()" size="small"
          >创建模板</el-button
        >
      </div>
      <!--创建模板 -->
      <el-dialog
        title="prompt模板"
        :show-close="true"
        :destroy-on-close="true"
        :visible.sync="adds.addshow"
        top="6vh"
        width="800px"
        ><div style="padding: 10px 30px">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="模板名称">
              <el-input v-model="form.name"></el-input>
            </el-form-item>

            <el-form-item label="模型名称">
              <el-select v-model="form.brand" placeholder="请选择大模型">
                <el-option label="deepseek" value="deepseek"></el-option>
                <el-option label="chatgpt" value="chatgpt"></el-option>
                <el-option label="百度文心一言" value="baidu"></el-option>
                <el-option
                  label="科大讯飞星火大模型"
                  value="xunfei"
                ></el-option>
                <el-option label="claude" value="claude"></el-option>
              </el-select>
            </el-form-item>
            <div style="padding-left:100px">
              <el-button type="text" @click="insertTextAtCursor('{QuestionLabel}','system','textarea2')">插入QuestionLabel</el-button>
            </div>
            
            <el-form-item label="system">
              <el-input
              ref="textarea2"
              type="textarea"
                :rows="5"
                v-model="form.system"
                placeholder="系统角色/背景"
              ></el-input>
            </el-form-item>
            <div style="padding-left:100px">
              <el-button type="text" @click="insertTextAtCursor('{logic}','temp','textarea')">插入文本逻辑</el-button>
              <el-button type="text" @click="insertTextAtCursor('{language}','temp','textarea')">插入语言</el-button>
            <el-button type="text" @click="insertTextAtCursor('{text}','temp','textarea')">插入句子</el-button>
            <el-button type="text" @click="insertTextAtCursor('{count}','temp','textarea')">插入数量</el-button>
            </div>
            
            <el-form-item label="prompt">
              <el-input
              ref="textarea"
              type="textarea"
                :rows="5"
                v-model="form.temp"
                placeholder="用户角色，您的提示词"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="form.generic" true-label="1" false-label="0">通用</el-checkbox
              ><span style="margin-left: 20px"></span>
              <el-button type="success" @click="add">保存模板</el-button>
              <el-button @click="promptTest">测试</el-button>             
            </el-form-item>
          </el-form>
          <div class="testinfo" v-show="testinfo.length > 0">
            {{ testinfo }}
          </div>
        </div>
      </el-dialog>
      <!--over-->

      <div class="temps">
        <el-table size="small" row-key="id" :data="temps" stripe fit border>
                <el-table-column label="模板名称" >
                  <template slot-scope="scope">
                    <span  v-if="scope.row.generic=='1'" style="color: #e60012;">[通用] </span>
                    <span>{{scope.row.name}}</span>                  
                  </template>

                </el-table-column>
                <el-table-column prop="brand" label="模型名称">
                  <template slot-scope="scope">
                    {{getBrand(scope.row.brand)}}
                  </template>
                </el-table-column>
                <el-table-column prop="temp" label="prompt" width="500">
                  <template slot-scope="scope">
                    {{len20(scope.row.temp)}}
                  </template>
                </el-table-column>         
                <el-table-column prop="created_time" label="创建时间"></el-table-column>     
                <el-table-column   label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" @click="view(scope.row)">查看</el-button>
                    <el-button type="text" @click="del(scope.$index,scope.row.id)">删除</el-button>      
                  </template>
                </el-table-column>              
              </el-table>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "OeAiTemp",
  props: ["pid"],
  data() {
    return {
   
      form: {
        brand: "baidu",
        name: "",
        system:"",
        temp: "",
        generic: "0",
        id:"",
      },
      adds:{
        tp:"1",//新增，0修改
        addshow: false,
      },
      temps: [],
      testinfo: "",
    };
  },

  methods: {
  len20(str){
    return str.length > 40 ? str.substring(0, 40) + '...' :str; 
  },
     // 插入文本到光标位置  
     insertTextAtCursor(textToInsert,rn,ri) {        
      var textarea = this.$refs[ri].$refs.textarea; // 获取textarea元素                
      var cursorPosition = textarea.selectionStart; // 获取光标位置
      var textBefore = this.form[rn].substring(0, cursorPosition);
      var textAfter = this.form[rn].substring(cursorPosition);

      // 更新textarea的值
      this.form[rn] = textBefore + textToInsert + textAfter;

      // 设置新的光标位置
      this.$nextTick(() => {
        textarea.focus();
        textarea.setSelectionRange(cursorPosition + textToInsert.length, cursorPosition + textToInsert.length);
      });
    },  
    getBrand(brand){
      switch(brand) {
        case "baidu":
          return "百度文心一言";
          case "xunfei":
          return "科大讯飞星火大模型";
          case "claude":
            return "claude";
            case "chatgpt":
            return "chatgpt";
            case "deepseek":
            return "deepseek";
      }
    },
    getPrompts() {
      var api = this.COMMON.httpUrl + "oeGetPrompt";
      var data = { pid: this.pid};      
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.temps = rs.data;
        }
      });
    },
    promptTest() {
      if (this.form.temp.trim().length == 0) {
        alert("prompt 不能为空");
        return;
      }
      var api = this.COMMON.httpUrl + "oePromptTest";
      var data = { temp: this.form.temp,system: this.form.system, brand: this.form.brand };
      this.testinfo = "waiting";
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
          this.testinfo = "";
        } else {
          this.testinfo = rs.data;
        }
      });
    },
    addform(){
      this.adds.addshow=true
      this.adds.tp="1"//新增
      this.testinfo=""
      this.form={
        brand: "baidu",
        name: "",
        temp: "",
        system:"",
        generic: "0",
        id:"",
      }
    },
    view(row) {
      this.testinfo=""
      this.adds.addshow=true;
      this.adds.tp="0" //修改      
      this.form.brand=row.brand;
      this.form.name=row.name;
      this.form.system=row.system;
      this.form.temp=row.temp;
      this.form.generic=String(row.generic);
      this.form.id=String(row.id);

    },
    add() {
      if (this.form.name.trim().length == 0) {
        alert("模板名称 不能为空");
        return;
      }
      if (this.form.temp.trim().length == 0) {
        alert("prompt 不能为空");
        return;
      }
      var api = this.COMMON.httpUrl + "oePromptAdd?pid="+this.pid+"&op="+this.adds.tp;
      var data = this.form;
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.temps = rs.data;
          this.adds.addshow=false        
        }
      });
    },
    del(index,tid) {
      var api = this.COMMON.httpUrl + "oePromptDel";
      var data = {
        tid:tid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.temps.splice(index, 1);
          this.$message.success(rs.message);
        }
      });
    },
  },
  created() {
     this.getPrompts();
  },
};
</script>
  <style scoped>
#content {
  background-color: white;
  padding-top: 40px;
  padding-left: 50px;
  width: 1200px;
}

.anl {
  border: 0px solid red;
  width: 600px !important;
}
.oelist {
  margin-top: 50px;
}
.testinfo {
  background-color: #efefef;
  padding: 10px;
  margin-top: 30px;
  color: #e6b857;
  font-weight: 800;
}
.temps{
  margin-top:30px
}
</style>
 
   