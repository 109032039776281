var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"margin-bottom":"10px","font-size":"14px"},attrs:{"id":"goback"}},[_c('i',{staticClass:"el-icon-back",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回")]),_vm._v("   "),_c('span',{staticStyle:{"font-size":"14px","color":"rgb(192, 196, 204)"}},[_vm._v("|")]),_vm._v("   "),_c('span',{staticStyle:{"color":"rgb(192, 196, 204)"}},[_vm._v("编号：")]),_vm._v(_vm._s(_vm.pcode)+" "),_c('span',{staticStyle:{"color":"rgb(192, 196, 204)"}},[_vm._v("项目：")]),_vm._v(_vm._s(_vm.pname)+" ")]),_c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"op"},[_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-zoom-in","disabled":_vm.doing},on:{"click":function($event){return _vm.operate('新建', null, null)}}},[_vm._v("创建reportal")]),_c('el-button',{attrs:{"size":"small","icon":"el-icon-view","disabled":_vm.doing},on:{"click":function($event){return _vm.topage('kpipv')}}},[_vm._v("PV 浏览")])],1),_c('div',{staticClass:"plist"},[_c('el-table',{staticStyle:{"width":"1200px"},attrs:{"data":_vm.tableData}},[(false)?_c('el-table-column',{attrs:{"prop":"id","label":"id"}}):_vm._e(),_c('el-table-column',{attrs:{"prop":"name","label":"报告名称","width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.name)),(scope.row.moren)?_c('span',{staticClass:"moren"},[_vm._v("默认")]):_vm._e(),(scope.row.open)?_c('span',{staticClass:"open"},[_vm._v("开放浏览")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"600"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"caozuo",attrs:{"to":{
                name: 'kpi',
                params: {
                  pid: _vm.pid,
                  kid: scope.row.id,
                  bname: scope.row.name,
                  pname: _vm.pname,
                  pcode: _vm.pcode,
                },
              }}},[_vm._v("查看")]),_c('router-link',{staticClass:"caozuo",attrs:{"to":{
                name: 'quotatab',
                params: {
                  pid: _vm.pid,
                  kid: scope.row.id,
                  bname: scope.row.name,
                  pname: _vm.pname,
                  pcode: _vm.pcode,
                },
              }}},[_vm._v("配额表")]),_c('router-link',{staticClass:"caozuo",attrs:{"to":{
                name: 'quotarun',
                params: {
                  pid: _vm.pid,
                  kid: scope.row.id,
                  bname: scope.row.name,
                  pname: _vm.pname,
                  pcode: _vm.pcode,
                },
              }}},[_vm._v("撞数")]),_c('el-button',{staticClass:"result",attrs:{"round":"","size":"mini","disabled":_vm.doing},on:{"click":function($event){return _vm.operate('复制', scope.row.name, scope.row.id)}}},[_vm._v("复制")]),_c('el-button',{staticClass:"result",attrs:{"round":"","size":"mini","disabled":_vm.doing},on:{"click":function($event){return _vm.operate('重命名', scope.row.name, scope.row.id)}}},[_vm._v("重命名")]),_c('el-button',{staticClass:"result",attrs:{"disabled":_vm.doing,"type":"danger","round":"","size":"mini","plain":""},on:{"click":function($event){return _vm.del(scope.row.id)}}},[_vm._v("删除")])]}}])}),_c('el-table-column',{attrs:{"prop":"created_time","label":"创建日期"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({ color: '#c0c4cc', 'font-size': '12px' })},[_vm._v(" "+_vm._s(scope.row.created_time)+" ")])]}}])})],1)],1),_c('div',{staticClass:"fenye"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-size":10,"layout":"total, prev, pager, next","total":_vm.total},on:{"current-change":_vm.getreport,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }