<template>
    <div>
        <div id="content">

            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="150px" style="width:600px">
                <el-form-item label="项目编号" prop="pcode">
                    <el-input v-model="ruleForm.pcode" placeholder="请输入项目编号"></el-input>
                </el-form-item>

                <el-form-item label="项目名称" prop="pname">
                    <el-input v-model="ruleForm.pname" placeholder="请输入项目名称"></el-input>
                </el-form-item>
                <el-form-item label="客户名称" prop="guestname">
                    <el-input v-model="ruleForm.guestname" placeholder="请输入客户名称">
                    </el-input>
                </el-form-item>
                <el-form-item label="样本量" prop="yangben">
                    <el-input-number v-model="ruleForm.yangben" :step="50" :min="100" :max="10000" label="句子基数">
                    </el-input-number>
                </el-form-item>
                <el-form-item label="执行天数" prop="zhixingtian">
                    <el-input v-model="ruleForm.zhixingtian" placeholder="请输入执行天数">
                    </el-input>
                </el-form-item>
                <el-form-item label="问卷时长" prop="wenjuanshichang">
                    <el-input v-model="ruleForm.wenjuanshichang" placeholder="请输入问卷时长">
                    </el-input>
                </el-form-item>

                <el-form-item label="complete数量" prop="completecount">
                    <el-input v-model="ruleForm.completecount" placeholder="请输入complete数量">
                    </el-input>
                </el-form-item>

                <el-form-item label="预估报价" prop="yugubaojia">
                    <el-input v-model="ruleForm.yugubaojia" placeholder="请输入预估报价">
                    </el-input>
                </el-form-item>
                <el-form-item label="实际结算价" prop="shijijiesuan">
                    <el-input v-model="ruleForm.shijijiesuan" placeholder="请输入实际结算价">
                    </el-input>
                </el-form-item>


                <el-form-item label="预估收款时间">
                    <el-date-picker v-model="ruleForm.yugushoukuan" type="date" format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd" placeholder="请输入选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="实际收款时间">
                    <el-date-picker v-model="ruleForm.shijishoukuan" type="date" format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd" placeholder="请输入选择日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="实际产生样本" prop="shijiyangben">
                    <el-input v-model="ruleForm.shijiyangben" placeholder="请输入实际产生样本">
                    </el-input>
                </el-form-item>
                <el-form-item label="利润率" prop="lirunlv">
                    <el-input v-model="ruleForm.lirunlv" placeholder="请输入利润率">
                    </el-input>
                </el-form-item>
                <el-form-item label="响应率" prop="xiangyinglv">
                    <el-input v-model="ruleForm.xiangyinglv" placeholder="请输入响应率">
                    </el-input>
                </el-form-item>
                <el-form-item label="甄别率" prop="zhenbielv">
                    <el-input v-model="ruleForm.zhenbielv" placeholder="请输入甄别率">
                    </el-input>
                </el-form-item>
                <el-form-item label="回复量及响应率" prop="huifulv">
                    <el-input v-model="ruleForm.huifulv" placeholder="请输入回复量及响应率">
                    </el-input>
                </el-form-item>
                <el-form-item label="渗透率" prop="shentou">
                    <el-input v-model="ruleForm.shentou" placeholder="请输入渗透率">
                    </el-input>
                </el-form-item>
                <el-form-item label="QC量" prop="qc">
                    <el-input v-model="ruleForm.qc" placeholder="请输入QC量">
                    </el-input>
                </el-form-item>
                <el-form-item label="中断量" prop="zhongduan">
                    <el-input v-model="ruleForm.zhongduan" placeholder="请输入中断量">
                    </el-input>
                </el-form-item>
                <el-form-item label="配额满量" prop="peieliang">
                    <el-input v-model="ruleForm.peieliang" placeholder="请输入配额满量">
                    </el-input>
                </el-form-item>
                <el-form-item label="平均完成时长" prop="pingjunshichang">
                    <el-input v-model="ruleForm.pingjunshichang" placeholder="请输入平均完成时长">
                    </el-input>
                </el-form-item>
                <el-form-item label="PM名" prop="pmname">
                    <el-input v-model="ruleForm.pmname" placeholder="请输入PM名">
                    </el-input>
                </el-form-item>
                <el-form-item label="客户cc单编号" prop="kehucc">
                    <el-input v-model="ruleForm.kehucc" placeholder="请输入客户cc单编号">
                    </el-input>
                </el-form-item>
                <el-form-item label="开票日期" prop="kaipiaoriqi">
                    <el-date-picker v-model="ruleForm.kaipiaoriqi" type="date" format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd" placeholder="请输入开票日期">
                    </el-date-picker>
                </el-form-item>              
                <el-form-item label="Hosting数量" prop="hosting">
                    <el-input v-model="ruleForm.hosting" placeholder="请输入Hosting数量">
                    </el-input>
                </el-form-item> 
                <el-form-item label="OE承接人-1" prop="oe1">
                    <el-input v-model="ruleForm.oe1" placeholder="请输入OE承接人-1">
                    </el-input>
                </el-form-item> 
                <el-form-item label="OE承接人-2" prop="oe2">
                    <el-input v-model="ruleForm.oe2" placeholder="请输入OE承接人-2">
                    </el-input>
                </el-form-item> 
                <el-form-item label="外包编程" prop="waibaobiancheng">
                    <el-input v-model="ruleForm.waibaobiancheng" placeholder="请输入外包编程">
                    </el-input>
                </el-form-item>                
                <el-form-item label="程序员名" prop="codername">
                    <el-input v-model="ruleForm.codername" placeholder="请输入程序员名">
                    </el-input>
                </el-form-item>
                <el-form-item label="QC名" prop="qcname">
                    <el-input v-model="ruleForm.qcname" placeholder="请输入QC名">
                    </el-input>
                </el-form-item>
                <el-form-item label="城市" prop="chengshi">
                    <el-input v-model="ruleForm.chengshi" placeholder="请输入城市">
                    </el-input>
                </el-form-item>
              
                <el-form-item label="城市题号" prop="cityname">
                    <el-input v-model="ruleForm.cityname" placeholder="请输入datamap中城市所对应的题号">
                    </el-input>
                </el-form-item>

                <el-form-item label="其他信息">
                    <el-checkbox v-model="ruleForm.zhujuan">设为主卷</el-checkbox>
                    <el-checkbox v-model="ruleForm.track">设为track</el-checkbox>
                    <el-checkbox v-model="ruleForm.kaipiao">是否开票</el-checkbox>
                    <el-checkbox v-model="ruleForm.shoukuan">是否收款</el-checkbox>
                </el-form-item>
                <el-form-item label="置顶显示">                    
                    <el-checkbox v-model="ruleForm.topup"></el-checkbox>
                </el-form-item>
                <el-form-item label="所属成员" prop="cy" class="members">
                    <el-checkbox-group v-model="ruleForm.members" >
                        <el-checkbox v-for="mem in memberoptions" :label="mem.id" :key="mem.id">{{ mem.name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')" :loading="loading">提交保存</el-button>
                    <el-button @click="onReset">重置</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>
<script>
export default {

    name: 'ProjectList',
    data() {
        return {
            loading: false,
            memberoptions: [],
            ruleForm: {
                pcode:'',
                pname: '',
                zhujuan: false,
                track: true,
                yangben: 10,
                zhixingtian: '',
                wenjuanshichang: '',
                yugubaojia: '',
                completecount: '',
                shijijiesuan: '',
                kaipiao: '',
                yugushoukuan: '',
                shijishoukuan: '',
                shoukuan: '',
                shijiyangben: '',
                lirunlv: '',
                sendimage: '',
                xiangyinglv: '',
                zhenbielv: '',
                huifulv: '',
                shentou: '',
                qc: '',
                zhongduan: '',
                peieliang: '',
                pingjunshichang: '',
                pmname: '',
                kehucc: '',
                kaipiaoriqi: '',
                hosting:'',
                oe1:'',
                oe2:'',
                waibaobiancheng: '',
                codername: '',
                qcname: '', 
                guestname:'',
                chengshi:'',
                cityname:'',               
                members: [],
                topup:''
            },

            rules: {

                pname: [
                    { required: true, message: '请输入项目名称', trigger: 'change' }

                ],
                zhixingtian: [
                    { required: true, message: '请输入执行天数', trigger: 'change' }

                ],
                wenjuanshichang: [
                    { required: true, message: '请输入问卷时长', trigger: 'change' }

                ],
                yugubaojia: [
                    { required: true, message: '请输入预估报价', trigger: 'change' }

                ]

            }

        }
    },
    mounted() {

        let api = this.COMMON.httpUrl + "member"
            let data = { pid: this.pid }
            this.axios.post(api, data).then((response) => {
                let rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {                   
                    this.memberoptions = rs.message
                }

            })
    },
    methods: {

        onReset() {
            // console.log(this.ruleForm.members)
            this.ruleForm.chong1 = false
            this.ruleForm.chong2 = false
            this.$refs["ruleForm"].resetFields();
        },
        onSuccess(res) {
            if (res.code) {
                this.$message({
                    message: res.message,
                    type: 'error'
                });
            } else {
                this.pexcel = res.message
            }

        },
        submitForm33() {
            console.log(this.ruleForm)
        },
        submitForm(formName) {
            console.log(this.ruleForm)

            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.loading = true
                    var api = this.COMMON.httpUrl + "projectAdd"

                    this.axios.post(api, this.ruleForm).then((response) => {
                        var rs = response.data
                        this.loading = false
                        if (rs.code) {
                            this.$message.error(rs.message);
                        } else {
                            // this.onReset()
                            console.log(rs)
                            this.$message.success('恭喜您,添加成功');
                             this.$router.push({ path: 'plist' })

                        }

                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });



        }

    }

}
</script>
<style scoped>
#content {
    background-color: white;
    width: 900px;
    text-align: left;
    padding-top: 40px;
}
.members{
    background-color: rgb(242, 246, 248);
    border-radius: 5px;
}
</style>