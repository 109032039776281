<template>
  <div>
    <el-dialog
      title="创建子项目"
      :visible.sync="child.childShow"
      top="20vh"
      width="750px"
    >
      <table>
        <tr>
          <td width="90">
            <el-button @click="childSave" type="primary" :loading="shuaxin">创建</el-button>
          </td>
          <td width="100"><el-button @click="childAdd" :loading="shuaxin">新增一个</el-button></td>
        </tr>
      </table>
      <div class="zxm">
        <p v-for="(item, index) in child.childData" :key="index">
          <el-input
            v-model="item.name"
            placeholder="请输入项目名称"
            style="width: 350px;margin-right:20px"
          ></el-input>
          <template>
            <el-radio v-model="item.remotestate" label="无状态">无状态</el-radio>
          <el-radio v-model="item.remotestate" label="test">test</el-radio>
  <el-radio v-model="item.remotestate" label="live">live</el-radio>
          </template>
          <el-button
            v-if="child.childData.length >= 0"
            type="text"
            icon="el-icon-remove-outline"
            style="color: #f56c6c; margin-left: 10px"
            @click="childDel(index)"
            >删除</el-button>
        </p>
      </div>
    </el-dialog>
    <div id="content">
      <div class="op">
        <el-button
          type="danger"
          size="small"
          icon="el-icon-delete"
          :loading="shuaxin"
          @click="del"
          v-if="perm.delete"
          >删除</el-button
        >
        <el-button
          v-if="perm.delete"
          type="info"
          plain
          size="small"
          icon="el-icon-download"
          @click="projectDown"
          :loading="shuaxin"
          >导出</el-button
        >
        <el-button
          type="info"
          plain
          size="small"
          icon="el-icon-refresh"
          @click="onrefresh"
          :loading="shuaxin"
          >刷新</el-button
        >
        <el-input  v-model="kword" placeholder="请输入项目编号或项目名称"  size="small"   @keypress.enter.native="onrefresh" @blur="onrefresh"  suffix-icon="el-icon-search"  clearable style="margin-left:80px;width:300px"></el-input>
      </div>
      <div class="plist">
        <el-table
          row-key="id"
          :data="tableData"
          ref="multipleTable"          
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column prop="id" label="id" v-if="false"> </el-table-column>

          <el-table-column sortable prop="pcode" label="项目编号" width="150">
            <template slot-scope="scope">
              <span  v-if="scope.row.pcode=='null'" style="color:#c0c4cc">null</span>
              <span  v-else>{{scope.row.pcode}}</span>
            </template>
          </el-table-column>
          <el-table-column sortable prop="pname" label="项目名称" width="300">
            <template slot-scope="scope">
              <span  v-if="scope.row.topup==1" style="color:red">[置顶]&nbsp;</span>
              <router-link
                :to="{
                  name: 'pchild',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                v-if="scope.row.parentid==0"
                >{{scope.row.pname}}</router-link
              >
              <span  v-if="scope.row.parentid">{{scope.row.pname}}</span>
            </template>
          </el-table-column>
          <el-table-column label="" width="120" v-if="perm.check">
            <template slot-scope="scope">
              <el-button
                round
                size="mini"
                @click="downresult(scope.row.resultdata)"
                icon="el-icon-download"
                v-show="scope.row.results"
                class="result"
                v-if="perm.result"
                >检测报告</el-button>
            </template>
          </el-table-column>
          <el-table-column label="检测" width="110" v-if="perm.check">
            <template slot-scope="scope">              
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-caret-right"
                @click="
                  task(
                    'checktaskrun',
                    scope.$index,
                    scope.row.id,
                    scope.row.pname
                  )
                "
                :disabled="scope.row.disabled"
                plain
              >
                {{ scope.row.tkstatus }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="550">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'pdata',
                  params: {
                    pid: scope.row.id,
                    ppcode: scope.row.pcode,
                    ppname: scope.row.pname,
                  },
                }"
                class="caozuo"
                v-if="perm.data"
                >项目数据</router-link
              >
              <el-button type="text" @click="childShow(scope.row.id)"
                >子项目</el-button
              >&nbsp;&nbsp;
              <router-link
                :to="{
                  name: 'pedit',
                  params: {
                    pid: scope.row.id,
                    ppcode: scope.row.pcode,
                    ppname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >编辑</router-link
              >
              <router-link
                :to="{
                  name: 'oldtonew',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >Oldtonew</router-link
              >
              <router-link
              v-if="perm.oe"
                :to="{
                  name: 'oeindex',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >OE</router-link
              >
              <router-link
                :to="{
                  name: 'info',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >Info</router-link
              >
              <router-link
                :to="{
                  name: 'logic',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >Logic</router-link
              >
              <router-link
                :to="{
                  name: 'instruct',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >Instruct</router-link
              >
              <router-link
                :to="{
                  name: 'reportlist',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >Kpi</router-link
              >         
            </template>
          </el-table-column>         
          <el-table-column
            sortable
            prop="created_time"
            label="创建日期"
            width="160"
          >
            <template slot-scope="scope">
              <div :style="{ color: '#c0c4cc', 'font-size': '12px' }">
                {{ scope.row.created_time }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="fenye">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageExcelList",
  data() {
    return {
      icon: "el-icon-caret-right",
      shuaxin: false,
      multipleSelection: [],
      tableData: [],
      currentPage: 1,
      kword:"",
      total: 1,
      token: "",
      perm: {
        delete: true,
        check: true,
        quota: true,
        dc: true,
        data: true,
        result: true,
        oe:true,
      },
      child: {
        parentId: "",
        childShow: false,
        childData: [{ name: "",remotestate:"无状态" }],
      },
    };
  },
  created() {
    const permission = localStorage.getItem("permission");
    var perm = JSON.parse(permission);
    this.perm = perm.project;
    // 这里是为了实现返回按钮记录上一个页码
    // this.handleCurrentChange(1)
    this.onrefresh();
    const token = localStorage.getItem("token");
    if (token) {
      this.token = token;
    }
  },

  methods: {
    search(){
      if(this.kword.trim().length>0){
        this.onrefresh()
      }
    },
    onrefresh() {
      this.shuaxin = true;
      this.currentPage = Number(localStorage.getItem("projectListCurrentPage"));
      this.handleCurrentChange(this.currentPage);
    },
    projectDown() {
      let durl =
        this.COMMON.httpUrl +
        "projectExportExcel?token=" +
        localStorage.getItem("token");
      window.location.href = durl;
    },
 
    task(tp, i, s, p) {
      // this.$set(this.tableData[i],"disabled",true)

      this.$confirm(
        "确定运行[" +
          p +
          "]任务吗?新的任务运行完毕后会将上次检测结果覆盖, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.taskRun(tp, i, s);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消任务",
          });
        });
    },
    taskRun(tp, i, pid) {
      var api = this.COMMON.httpUrl + tp;
      var data = {
        pid: pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.$set(this.tableData[i], "disabled", true);

          this.$set(this.tableData[i], "results", false);
          switch (tp) {
            case "checktaskrun":
              this.$set(this.tableData[i], "tkstatus", "检测中");
              break;
            case "quotataskrun":
              this.$set(this.tableData[i], "quotastatus", "处理中");
              break;
            case "dctaskrun":
              this.$set(this.tableData[i], "dcstatus", "处理中");
              break;
          }
        }
      });
    },
    handleCurrentChange(page) {
      var api = this.COMMON.httpUrl + "projectList";
      var data = {
        page: page,
        kword:this.kword.trim()
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.tableData = rs.data.data;
          this.currentPage = rs.data.current_page;
          this.total = rs.data.total;

          for (var i = 0; i < this.tableData.length; i++) {
            switch (this.tableData[i].status) {
              case 2: //检测中
                this.$set(this.tableData[i], "disabled", true);
                this.$set(this.tableData[i], "tkstatus", "检测中");
                this.$set(this.tableData[i], "results", false);
                break;
              case 3: //检测完,有结果下载
                this.$set(this.tableData[i], "disabled", false);
                this.$set(this.tableData[i], "tkstatus", "check");
                this.$set(this.tableData[i], "results", true);
                break;
              default: //未检测过
                this.$set(this.tableData[i], "disabled", false);
                this.$set(this.tableData[i], "tkstatus", "check");
                this.$set(this.tableData[i], "results", false);
            }
            switch (this.tableData[i].quotastatus) {
              case 1: //默认
                this.$set(this.tableData[i], "quotastatus", "quota");
                break;
              case 2:
                this.$set(this.tableData[i], "quotastatus", "处理中");
                this.$set(this.tableData[i], "disabled", true);
            }
            switch (this.tableData[i].dcstatus) {
              case 1: //默认
                this.$set(this.tableData[i], "dcstatus", "dc");
                break;
              case 2:
                this.$set(this.tableData[i], "dcstatus", "处理中");
                this.$set(this.tableData[i], "disabled", true);
            }
          }
        }

        localStorage.setItem("projectListCurrentPage", this.currentPage);
        this.shuaxin = false;
      });
    },
    del() {
      var s = [];
      this.$refs.multipleTable.selection.forEach((Ele) => {
        s.push(Ele.id);
      });
      if (s.length == 0) {
        return;
      }

      this.$confirm(
        "删除将会影响该项目运行中和待运行的任务, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.savedel();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    downresult(pname) {
      window.location.href = this.COMMON.httpUrl + "storage/" + pname;
    },
    savedel() {
      var s = [];
      this.$refs.multipleTable.selection.forEach((Ele) => {
        s.push(Ele.id);
      });

      var api = this.COMMON.httpUrl + "projectdel";
      var data = {
        pids: s,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.onrefresh();
        }
      });
    },
    childShow(pid) {
      this.child.parentId = pid;
      this.child.childShow = true;
    },
    childAdd() {
      this.child.childData.push({ name: "",remotestate:"无状态" });
    },
    childSave2() {
      for(let c of this.child.childData){
       console.log(c.name,c.remotestate)
      }
    },
    childSave() {
      for(let c of this.child.childData){
        if (c.name.trim() == ""){
          alert("子项目名称不能为空")
          return
        }
      }
      this.shuaxin=true
      var api = this.COMMON.httpUrl + "projectChildAdd";
      var data = {
        parentId: this.child.parentId,
        names: this.child.childData,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        this.shuaxin=false
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.onrefresh();
          this.$message.success(rs.message);
          this.child.childShow = false;
          this.child.childData = [{ name: "","remotestate":"无状态" }];
        }
      });
    },
    childDel(index) {
      this.child.childData.splice(index, 1);
    },
  },
};
</script>
<style scoped>
#content {
  background-color: white;
  /* width: 1360px; */
  text-align: left;
  padding-left: 0px;
}
.plist{
  border:0px solid red;
  width: 1560px;
}
.bianhao {
  color: red;
}

.op {
  padding: 20px 0;
}

.fenye {
  margin-top: 20px;
}

.cell a {
  color: #666;
  text-decoration: none;
}

.cell a:hover {
  color: rgb(125, 163, 234);
  text-decoration: underline;
}

.caozuo {
  color: #409eff !important;
  margin-right: 10px;
}

.result {
  color: #409eff !important;
  margin-left: 10px;
}
.zxm {
}
.zxm p {
  margin-top: 15px;
}
</style>