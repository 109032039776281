<template>
  <div>
    <div id="content">
      <div class="anl">       
        <el-button
          type="danger"
          size="small"
          @click="delall('oeJuClear')">清空导入记录</el-button>
        <el-upload
          class="upload"
          name="ju"
          :action="upurl"
          :headers="{ Authorization: token }"
          :data="{ pid: pid }"
          auto-upload
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-success="onSuccess"
          v-loading="loading"
          ><el-button type="primary"  size="small">导入句子</el-button></el-upload
        > <span style="font-size:14px">共导入 {{totalCount}} 条</span>
      </div>
       <div class="part1">     
        模板：  <el-select v-model="form.promptid" placeholder="请选择模板" size="small">
          <el-option
      v-for="item in prompt"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
              </el-select>
        <span> 数量：</span>  <el-select v-model="form.count" size="small" style="width:100px">                
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>
                <el-option label="7" value="7"></el-option>
                <el-option label="8" value="8"></el-option>
                <el-option label="9" value="9"></el-option>
                <el-option label="10" value="10"></el-option>
              </el-select>
              <span></span><el-button
          type="primary"
          size="small"
          @click="creatTask()"> 文字质量检查</el-button>
       </div>

       <div class="task">
        <div class="tit">
          <el-button type="danger" plain size="mini"  @click="delall('oeAiTextClear')">清空质量检查原句</el-button>
          <el-button type="primary" plain size="mini"  @click="pdowndata('ai句子.xlsx', 'oeAiTextDown', '下载异常','0')">下载质量检查原句</el-button>        
          <el-button type="primary" plain size="mini" @click="getJuInfo">刷新任务</el-button>
          <span style="font-size:14px;margin-left:15px">共生成 {{aiTxtCount}} 条</span>
        </div>
        <el-table size="small" row-key="id" :data="task" stripe fit border>
                <el-table-column label="模板名称" >
                  <template slot-scope="scope">
                    <span  v-if="scope.row.generic=='1'" style="color: #e60012;">[通用] </span>
                    <span>{{scope.row.promptname}}</span>                  
                  </template>
                </el-table-column>                
                <el-table-column prop="start_time" label="开始时间" width="150"></el-table-column>         
                <el-table-column prop="end_time" label="结束时间"  width="150"></el-table-column>         
                <el-table-column prop="jucount" label="原句数量">                 
                </el-table-column>              
                <el-table-column prop="aicount" label="生成数量">                 
                </el-table-column>
                <el-table-column prop="progress" label="进度">
                  <template slot-scope="scope">
                    {{scope.row.progress}}%
                  </template>

                </el-table-column>     
                <el-table-column prop="status" label="状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status==0" style="color:#e60012">进行中</span>
                    <span v-else>已完成</span>
                  </template>
                </el-table-column>    
                <el-table-column  label="下载">
                  <template slot-scope="scope">                    
                    <el-button type="text" plain size="mini"  @click="pdowndata('ai句子.xlsx', 'oeAiTextDown', '下载异常',scope.row.id)">下载Ai句子</el-button>
                  </template>
                </el-table-column>    
        </el-table>
       </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "OeSentenceManage",
  props: ["pid","current"],
  data() {
    return {
      upurl: this.COMMON.httpUrl + "oeJuImport",
      token: "Bearer " + localStorage.getItem("token"),
      loading:false,
      totalCount:0,
      form:{
        count:"1",
        promptid:"",
      },
      prompt:[],
      task:[],
      aiTxtCount:0,
    };
  },
  watch: {  
    current(newVal) {  
      if (newVal) {  
        this.getJuInfo();
      }  
    }  
  },  
  methods: {  
    getJuInfo() {
      var api = this.COMMON.httpUrl + "oeJuInfo";
      var data = {"pid":this.pid};
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.totalCount=rs.data.totalCount;
          this.aiTxtCount=rs.data.aiTxtCount;
          this.prompt=rs.data.prompt;
          this.task=rs.data.task;
        }
      });
    },
    
    creatTask(){
      if(this.form.promptid.length==0){
        alert("请选择模板");
        return;
      }
      var api = this.COMMON.httpUrl + "oeCreatTask";
      var data = {"pid":this.pid,"count":this.form.count,"promptid":this.form.promptid};
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);        
          this.task=rs.data;
        }
      });
    },
    beforeAvatarUpload(file) {
     
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        return false;
      }
      this.loading = true;
    },
    onSuccess(res) {
      if (res.code) {
        this.$message.error(res.message);
      } else {
        this.$message.success(res.message);        
        this.totalCount = res.data;
      }
      this.loading = false

    },
 
    delall(url) {
      var api = this.COMMON.httpUrl + url;
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          if (url=='oeAiTextClear'){
            this.aiTxtCount = 0;
          }else{
            this.totalCount = 0;
          }
          
          this.$message.success(rs.message);
        }
      });
    },
 
     // 文件下载
     pdowndata(fname, url, er,taskId) {
      this.loading3=true
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify({ pid: this.pid,taskId:taskId}),
      };

      let src = this.COMMON.httpUrl + url;
      fetch(src, requestOptions)
        .then((response) => {
          this.loading3=false
          if (!response.ok) {
            // 如果响应不是成功的，尝试读取JSON格式的错误信息
            return response.json().then((errorData) => {
              // 假设错误数据是一个包含code和message的对象
              if (errorData.code && errorData.message) {
                // 抛出错误以便后续的.catch可以捕获
                throw new Error(errorData.message);
              } else {
                // 如果格式不符合预期，可以抛出一个通用的错误
                throw new Error(er || "未知错误");
              }
            });
          }
          return response.blob();
        })
        // .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          // 文件名称可以在这里更改
          a.download = fname;
          a.href = url;
          a.click();
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
  created() {
    this.getJuInfo();
  },
};
</script>
  <style scoped>
#content {
  background-color: white;
  padding-top: 40px;
  padding-left: 50px;
  width:1100px;
}

.anl {
 border:0px solid red;
  width: 600px!important;
}
.oelist{
  margin-top:50px;
}
.upload {
  display: inline-block;
  margin: 0 8px;
}
.part1{
  margin-top:60px;
}
.part1 span{
  margin-left:20px;
}
.task{
  margin-top:100px;
}
.task .tit{
  margin:10px 5px;
}
</style>
 
   